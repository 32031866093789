import React, { useRef, useEffect } from "react";
import { Shim_HTMLMediaElement } from "../utils";

const Counter = () => {
  const canvasRef = useRef<HTMLCanvasElement & Shim_HTMLMediaElement>(null);
  const requestRef = useRef<number | null>(null);

  useEffect(() => {
    if (canvasRef.current !== null) {
      const canvas = canvasRef.current;
      const stream = canvas.captureStream();

      const pc = new RTCPeerConnection();
      stream.getVideoTracks().forEach((t) => pc.addTrack(t, stream));
      console.log(stream.getVideoTracks());
      const ws = new WebSocket("ws://localhost:5080/LiveApp/websocket");
      (async () => {
        const candidates: RTCIceCandidate[] = [];
        ws.addEventListener("message", async ({ data: rawData }) => {
          const data = JSON.parse(rawData);
          console.log("incoming message", data);
          const { command } = data;
          if (command === "start") {
            const offer = await pc.createOffer();
            pc.setLocalDescription(offer);
            ws.send(
              JSON.stringify({
                command: "takeConfiguration",
                streamId: "945779697203572002031868",
                type: "offer",
                sdp: offer.sdp,
              })
            );
          } else if (
            command === "takeConfiguration" &&
            data.type === "answer"
          ) {
            console.log("answer", data);
            pc.setRemoteDescription(data);
            for (let candidate of candidates) {
              console.log("add candidates");
              await pc.addIceCandidate(candidate);
            }
          } else if (command === "takeCandidate") {
            const { candidate, label: sdpMLineIndex, id: sdpMid } = data;
            // candidates.push({ candidate, sdpMLineIndex, sdpMid } as any);
            pc.addIceCandidate({ candidate, sdpMLineIndex, sdpMid } as any);
          }
        });

        ws.addEventListener("open", () => {
          pc.addEventListener("icecandidate", (evt) => {
            console.log("sending candidate");
            if (evt.candidate) {
              ws.send(
                JSON.stringify({
                  command: "takeCandidate",
                  streamId: "945779697203572002031868",
                  label: evt.candidate.sdpMLineIndex,
                  id: evt.candidate.sdpMid,
                  candidate: evt.candidate.candidate,
                })
              );
            }
          });

          ws.send(
            JSON.stringify({
              command: "publish",
              streamId: "945779697203572002031868",
              video: true,
              audio: false,
            })
          );
        });
      })();
    }

    const animate = (time: number) => {
      if (canvasRef.current !== null) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        if (ctx !== null) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.save();
          ctx.fillStyle = "rgba(90, 0, 0, 1)";
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.restore();
          ctx.font = "48px sans-serif";
          ctx.fillStyle = "rgba(255, 255, 255, 1)";
          ctx.fillText(`${time}`, 10, 50);
        }
      }

      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current !== null) {
        cancelAnimationFrame(requestRef.current);
      }

      // pc.close();
    };
  });
  return <canvas ref={canvasRef} width="640" height="480" />;
};

export default Counter;
