import React, { useEffect, useRef } from "react";
import Container from "./Container";
import { RouteComponentProps } from "react-router-dom";
import { generateRandomSessionId, makeWS } from "../../utils";

const Camera = (props: RouteComponentProps<{ id: string }>) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const pc = new RTCPeerConnection();
    let ws: WebSocket | null = null;
    (async () => {
      // Setup camera video
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }

      // Set up p2p video streaming
      const sessionId = generateRandomSessionId();
      const slotId = +props.match.params.id;
      ws = await makeWS();

      // Forward candidates as they trickle in
      pc.addEventListener("icecandidate", (evt) => {
        if (evt.candidate) {
          ws?.send(
            JSON.stringify({
              sessionId,
              kind: "icecandidate",
              data: {
                sdpMLineIndex: evt.candidate.sdpMLineIndex,
                sdpMid: evt.candidate.sdpMid,
                candidate: evt.candidate.candidate,
              },
            })
          );
        }
      });

      const candidates: RTCIceCandidate[] = [];
      ws.addEventListener("message", async ({ data: rawData }) => {
        const { sessionId: incomingSessionId, data, kind } = JSON.parse(
          rawData
        );
        if (sessionId !== incomingSessionId) {
          return; // skip messages not directly addressed to me
        }

        if (kind === "ready-for-offer") {
          const offer = await pc.createOffer();
          pc.setLocalDescription(offer);
          ws?.send(
            JSON.stringify({
              kind: "offer",
              data: offer,
              sessionId,
            })
          );
        } else if (kind === "answer") {
          await pc.setRemoteDescription(data);
          for (let candidate of candidates) {
            await pc.addIceCandidate(candidate);
          }
        } else if (kind === "icecandidate") {
          candidates.push(data);
        }
      });

      stream.getTracks().forEach((t) => pc.addTrack(t, stream));

      ws.send(
        JSON.stringify({
          kind: "camera-available",
          sessionId,
          data: {
            slotId,
          },
        })
      );
    })();
    return () => {
      pc.close();
      ws?.close();
    };
  }, [props.match.params.id]);

  return (
    <Container
      main={() => (
        <video
          ref={videoRef}
          width={640}
          height={480}
          style={{
            transform: "scaleX(-1)",
            border: "2px solid #EFEFEF",
            borderRadius: 5,
            backgroundColor: "white",
            boxShadow: "0 0 10px rgba(0, 0, 0, .5)",
            width: "75%",
            height: "auto",
          }}
          autoPlay={true}
          muted={true}
          playsInline={true}
        />
      )}
    />
  );
};

export default Camera;
