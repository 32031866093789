import React from "react";

const Container = (props: { main: () => JSX.Element; sidebar?: () => JSX.Element }) => {
  return (
    <>
      <div style={{ backgroundColor: "#61dafb", width: "100vw", height: 50, display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
        <div style={{ margin: 15, fontSize: 30, fontFamily: "'Lobster', cursive" }}>loom</div>
      </div>
      <div style={{ display: "flex", height: "calc(100vh - 50px)", overflow: "hidden" }}>
        <div style={{ backgroundColor: "#3f3f3f", flex: "4 1 auto" }}>
          <div style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}>
            <props.main />
          </div>
        </div>
        {
          props.sidebar ?
            <div style={{ backgroundColor: "#2d2d2d", padding: 16, width: 300, overflowY: "scroll" }}>
              <props.sidebar />
            </div>
            : null
        }
      </div>
    </>
  )
};

export default Container;
