import React, { useRef, useEffect } from "react";
import { makeWS } from "../../utils";
import Container from "./Container";
import VideoComposite from "../VIdeoComposite";

const Studio = () => {
  const videoPreviews = useRef(
    new Array(10).fill(undefined).map(() => ({
      ref: React.createRef<HTMLVideoElement>(),
      pc: new RTCPeerConnection(),
      sessionId: "",
    }))
  );

  const livePreview = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    let ws: WebSocket | null = null;
    (async () => {
      ws = await makeWS();
      ws.addEventListener("message", async ({ data: rawData }) => {
        const { sessionId, data, kind } = JSON.parse(rawData);
        console.log("received message", JSON.parse(rawData));
        if (kind === "camera-available") {
          const { slotId } = data;
          const {
            pc: existingPc,
            sessionId: existingSessionId,
          } = videoPreviews.current[slotId];
          if (existingSessionId) {
            console.log("existing session for camera", slotId);
            existingPc.close();
          }

          const pc = new RTCPeerConnection();
          videoPreviews.current[slotId].pc = pc;
          videoPreviews.current[slotId].sessionId = sessionId;

          pc.addEventListener("icecandidate", (evt) => {
            if (evt.candidate) {
              ws?.send(
                JSON.stringify({
                  sessionId,
                  kind: "icecandidate",
                  data: {
                    sdpMLineIndex: evt.candidate.sdpMLineIndex,
                    sdpMid: evt.candidate.sdpMid,
                    candidate: evt.candidate.candidate,
                  },
                })
              );
            } else {
              console.log("end of candidates");
            }
          });

          pc.addEventListener("track", (evt) => {
            videoPreviews.current[slotId].ref.current!.srcObject =
              evt.streams[0];
          });

          ws?.send(
            JSON.stringify({
              sessionId,
              kind: "ready-for-offer",
            })
          );
        } else {
          const slots = videoPreviews.current.filter(
            ({ sessionId: sid }) => sessionId === sid
          );
          if (slots.length !== 1) {
            throw new Error("unexpected session state");
          }

          const pc = slots[0].pc;

          if (kind === "offer") {
            await pc.setRemoteDescription(data);
            const answer = await pc.createAnswer(data);
            await pc.setLocalDescription(answer);
            ws?.send(
              JSON.stringify({
                kind: "answer",
                data: answer,
                sessionId,
              })
            );
          } else if (kind === "icecandidate") {
            await pc.addIceCandidate(data);
          }
        }
      });
    })();
  }, []);

  return (
    <Container
      main={() => <VideoComposite cover={livePreview} />}
      sidebar={() => (
        <>
          {videoPreviews.current?.map(({ ref }) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <video
                onClick={() => { livePreview.current = ref.current }}
                ref={ref}
                // width={640}
                // height={480}
                style={{
                  transform: "scaleX(-1)",
                  border: "2px solid #EFEFEF",
                  borderRadius: 5,
                  backgroundColor: "white",
                  boxShadow: "0 0 10px rgba(0, 0, 0, .5)",
                  width: "75%",
                  height: "auto",
                  margin: 20,
                }}
                autoPlay={true}
                muted={true}
                playsInline={true}
              />
            </div>
          ))}
        </>
      )}
    />
  );
};

export default Studio;
