import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Camera from "./components/views/Camera";
import Studio from "./components/views/Studio";
import Counter from "./components/Counter";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/cameras/:id" component={Camera} />
        <Route path="/studio" component={Studio} />
        <Route path="/counter" component={Counter} />
        <Route path="/" component={P} />
      </Switch>
    </Router>
  );
};

const P = () => <p>a</p>;

export default App;
