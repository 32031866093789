import cryptoRandomString from "crypto-random-string";

export const WS_URL =
  process.env.REACT_APP_WS_URL || "ws://localhost:3400/sockets";

export const generateRandomSessionId = () => {
  return cryptoRandomString({ length: 10, type: "base64" });
};

export const makeWS = () => {
  const ws = new WebSocket(WS_URL);
  ws.addEventListener("close", () => console.log("[websocket] closed"));
  ws.addEventListener("error", (error) =>
    console.log("[websocket] error", error)
  );
  return new Promise<WebSocket>((res) => {
    ws.addEventListener("open", () => {
      console.log("[websocket] opened");
      res(ws);
    });

    ws.addEventListener("error", (error) => {
      console.log("[websocket] error", error);
    });

    ws.addEventListener("close", () => {
      console.log("[websocket] closed");
    });
  });
};

export const emptyArray = (l: number) => {
  const a = [];
  for (let i = 0; i < l; i++) {
    a.push(i);
  }

  return a;
};

export const drawInBoundingBox = (
  canvas: HTMLCanvasElement,
  video: HTMLVideoElement,
  x: number,
  y: number,
  width: number,
  height: number,
  flipped?: boolean,
) => {
  const ctx = canvas.getContext("2d");
  const scale = Math.min(width / video.videoWidth, height / video.videoHeight);
  const xn = x + (width - video.videoWidth * scale) / 2 * (flipped ? -1 : 1);
  const yn = y + (height - video.videoHeight * scale) / 2;

  if (ctx !== null) {
    ctx.save();
    ctx.scale(flipped ? -1 : 1, 1);
    ctx.drawImage(
      video,
      xn,
      yn,
      video.videoWidth * scale * (flipped ? -1 : 1),
      video.videoHeight * scale
      );
    ctx.restore();
  }
};

export interface Shim_HTMLMediaElement {
  captureStream(): MediaStream;
}
