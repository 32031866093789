import React, { useRef, useEffect } from "react";
import { drawInBoundingBox } from "../utils";

interface PositionedVideoSlots {
  cover?: React.RefObject<HTMLVideoElement | null>;
  left?: React.RefObject<HTMLVideoElement | null>;
  right?: React.RefObject<HTMLVideoElement | null>;
  upper?: React.RefObject<HTMLVideoElement | null>;
  lower?: React.RefObject<HTMLVideoElement | null>;
  upperRight?: React.RefObject<HTMLVideoElement | null>;
  upperLeft?: React.RefObject<HTMLVideoElement | null>;
  lowerLeft?: React.RefObject<HTMLVideoElement | null>;
  lowerRight?: React.RefObject<HTMLVideoElement | null>;
}

const VideoComposite = (props: PositionedVideoSlots) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const requestRef = useRef<number | null>(null);

  useEffect(() => {
    const animate = (time: number) => {
      if (canvasRef.current !== null) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        if (ctx !== null) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          if (props.cover && props.cover.current) {
            drawInBoundingBox(
              canvas,
              props.cover.current,
              0,
              0,
              canvas.width,
              canvas.height,
              true,
            );
          }

          ctx.font = "48px sans-serif";
          ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
          ctx.fillText(`${time}`, 10, 50);
        }
      }
      requestRef.current = requestAnimationFrame(animate);
    };

    console.log("use effect");
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      console.log("cancel effect");
      if (requestRef.current !== null) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [
    props.cover,
    props.left,
    props.right,
    props.upper,
    props.lower,
    props.upperLeft,
    props.upperRight,
    props.lowerLeft,
    props.lowerRight,
  ]);

  return (
    <canvas
      ref={canvasRef}
      width={640}
      height={480}
      style={{
        border: "2px solid #EFEFEF",
        borderRadius: 5,
        backgroundColor: "white",
        boxShadow: "0 0 10px rgba(0, 0, 0, .5)",
        width: "75%",
        height: "auto",
        margin: 20,
        // padding: 10,
      }}
    />
  );
};

export default VideoComposite;
